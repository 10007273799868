export default {
  namespaced: true,
  state: {
    cropCode: 0, // 作物Code
    cropName: '', // 作物名称
    cropImgUrl: '', // 作物图片
    areaId: null, // 区域ID
    areaCode: 5114, // 区域编码（默认眉山市）511528(兴文县)
    areaCodeArr: ['5114'], // 区域编码绑定的值（默认眉山市） ["5115","511528"](兴文县)
    nowPeriod: '', // 当前生育期
    zzhMsg: {}, // 种植户信息（AI）页面使用
    curYear: '2024', // 当前年份（农险数据、AI农业监测都使用）
    curSeason: '大春', // 当前季节
    cityName: '眉山市', // 用于展示标题 眉山市
    fitDetail: {}, // 适宜度（温度、降水、日照）
    nowCropCode: null, // 当前选中点位的作物Code
    activeDomNode: null, // 当前激活的点位节点的id
  },
  mutations: {
    setCropCode(state, cropCode) {
      // console.log(`cropCode :>>`, cropCode)
      state.cropCode = cropCode;
    },
    setCropName(state, cropName) {
      // console.log(`cropName :>>`, cropName);
      state.cropName = cropName;
    },
    setCropImgUrl(state, cropImgUrl) {
      state.cropImgUrl = cropImgUrl;
    },
    setAreaId(state, areaId) {
      state.areaId = areaId;
    },
    setAreaCode(state, areaCode) {
      state.areaCode = areaCode;
    },
    setNowPeriod(state, nowPeriod) {
      state.nowPeriod = nowPeriod;
    },
    setZzhMsg(state, zzhMsg) {
      state.zzhMsg = zzhMsg;
    },
    setCurYear(state, curYear) {
      state.curYear = curYear;
    },
    setCurSeason(state, curSeason) {
      state.curSeason = curSeason;
    },
    setAreaCodeArr(state, areaCodeArr) {
      state.areaCodeArr = areaCodeArr;
    },
    setCityName(state, cityName) {
      state.cityName = cityName;
    },
    setFitDetail(state, fitDetail) {
      state.fitDetail = fitDetail;
    },
    setNowCropCode(state, nowCropCode) {
      state.nowCropCode = nowCropCode;
    },
    setActiveDomNode(state, activeDomNode) {
      state.activeDomNode = activeDomNode;
    },
  },
  actions: {
    setAi(context, payload) {
      context.commit('setCropCode', null);
      context.commit('setCropName', null);
      context.commit('setAreaId', null);
      context.commit('setNowPeriod', null);
    },
  },
};
