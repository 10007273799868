import {set} from 'nprogress';

export default {
  namespaced: true,
  state: {
    zzhInfo: {}, // 种植户信息（household）页面使用
    xzzw: {}, // 选择作物(园区和种植员都使用)(icon:图标url，label:作物中文名称，value:作物代码)
    syq: null, // 当前作物的生育期
  },
  mutations: {
    setZzhInfo(state, zzhInfo) {
      state.zzhInfo = zzhInfo;
    },
    setXzzw(state, xzzw) {
      state.xzzw = xzzw;
    },
    setSyq(state, syq) {
      state.syq = syq;
    },
  },
  actions: {},
};
