import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import Router from 'vue-router';

Vue.use(Router);

// 页面跳转时的进度条
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import store from '@/store/tag';
import storeIndex from '@/store/index';
NProgress.configure({
  easing: 'ease', // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载icon
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 初始化时的最小百分比
});

Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    redirect: '/agro',
  },
  // 登录页
  // {
  //   path: "/login",
  //   component: () => import("../views/login/index.vue"),
  // },
  // // 地块管理

  // {
  //   path: "/login",
  //   redirect: "/login",
  //   meta: { titel: "登录" },
  //   hidden: true,
  //   component: () => import("../views/login/index.vue"),
  //   children: [
  //     {
  //       path: "/login",
  //       name: "登录",
  //       meta: { titel: "登录" },
  //       component: () => import("../views/login/index.vue"),
  //     },
  //   ],
  // },

  {
    path: '/agro',
    redirect: '/agro',
    meta: {titel: '农业'},
    component: () => import('../views/agro/index.vue'),
    children: [
      {
        path: '/agro',
        name: '农业',
        meta: {titel: '农业'},
        component: () => import('../views/agro/index.vue'),
      },
    ],
  },
  {
    path: '/insurance',
    redirect: '/insurance',
    meta: {titel: '农险'},
    component: () => import('../views/insurance/index.vue'),
    children: [
      {
        path: '/insurance',
        name: '农险',
        meta: {titel: '农险'},
        component: () => import('../views/insurance/index.vue'),
      },
    ],
  },
  {
    path: '/AI',
    redirect: '/AI',
    meta: {titel: 'AI农业检测'},
    component: () => import('../views/AI/index.vue'),
    children: [
      {
        path: '/AI',
        name: 'AI农业检测',
        meta: {titel: 'AI农业检测'},
        component: () => import('../views/AI/index.vue'),
      },
    ],
  },
  {
    path: '/garden',
    redirect: '/garden',
    meta: {titel: '示范园区'},
    component: () => import('../views/garden/index.vue'),
    children: [
      {
        path: '/garden',
        name: '示范园区',
        meta: {titel: '示范园区'},
        component: () => import('../views/garden/index.vue'),
      },
    ],
  },
  {
    path: '/household',
    redirect: '/household',
    meta: {titel: '个人种植情况'},
    component: () => import('../views/household/index.vue'),
    children: [
      {
        path: '/household',
        name: '个人种植情况',
        meta: {titel: '个人种植情况'},
        component: () => import('../views/household/index.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: 'hash',
});

router.beforeEach((to, from, next) => {
  // 每次切换页面时，调用进度条
  NProgress.start();
  // 从AI页面跳出去时，store中ai模块设置为空
  if (from.path === '/AI') {
    storeIndex.dispatch('ai/setAi', {});
  }
  // store.commit("addRoute", to);
  // store.commit("setRouterTagActive", to);
  next();
});
// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
let routerReplace = Router.prototype.replace;
// push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err);
};
// replace
Router.prototype.replace = function push(location) {
  return routerReplace.call(this, location).catch(err => err);
};

//当路由进入后：关闭进度条
router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done();
});
export default router;
