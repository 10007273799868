import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import MapboxPlugin from './utils/MapboxPlugin';
// main.js
import VScaleScreen from 'v-scale-screen';
import scroll from 'vue-seamless-scroll';
Vue.use(scroll);
Vue.use(VScaleScreen);
import 'element-ui/lib/theme-chalk/index.css';
import './assets/global.css';
import './assets/iconfont/font_4508819_uz90ts66duo/iconfont.css';
import './icon/index';
import './utils/flexible.js';
import './utils/rem';
import './utils/directive'; //引入全局拖拽
import './style.css';
import '@/assets/iconfont/font_1/iconfont.css';
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(MapboxPlugin);
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
